const _ = require("lodash")
import ACTION_TYPES from "./datatables/action_types.mjs"
import renderActions from "./datatables/actions.mjs"


function createAcaoConsultar(acao, url, tipo) {
    return createAcao(
        acao,
        url,
        'btnConsultar',
        'Consultar',
        'Consultar',
        tipo,
        'find_in_page',
        true
    );
}

function createAcaoAlterar(acao, url, tipo) {
    return createAcao(
        acao,
        url,
        'btnAlterar',
        'Alterar',
        'Alterar',
        tipo,
        'edit',
        true
    );
}

function createAcaoDesativar(acao, url, tipo) {
    return createAcao(
        acao,
        url,
        'btnDesativar',
        'Desativar',
        'Desativar',
        tipo,
        'delete',
        true
    );
}

function createAcao(acao,
                    url,
                    strClass,
                    strLabel,
                    strTitle,
                    strTipo,
                    strIcon,
                    isMD) {
    strTipo = strTipo === undefined ? ACTION_TYPES.NORMAL : strTipo;
    strIcon = strIcon === undefined ? 'error' : strIcon;
    isMD = isMD === undefined ? true : isMD;

    return {
        url: url,
        acao: acao,
        data: [],
        class: strClass,
        label: strLabel,
        tipo: strTipo,
        title: strTitle,
        name: strClass,
        id: strClass,
        icon: strIcon,
        MD: isMD,
        width: 1200,
        height: 700,
    };
}


function build(opts) {
    const idFormParams = opts.ajaxData.idFormParams
    const tableEl = $('#' + opts.tableId)
    const noop = () => undefined
    const onSelectCallback = opts.onSelectCallback || noop
    const dtAttributes = opts.otherDTAttributes || {}
    const drawCallback = settings => opts.drawCallbacks.forEach(fn => fn(settings))

    var ajax = {
        "url": tableEl.data('url'),
        "type": "POST",
        "data": function (data) {
            if (opts.ajaxData.acoes) {
                data['dt_acoes'] = opts.ajaxData.acoes;
            }

            if (opts.ajaxData.paramsComuns) {
                data['dt_paramsComunsAcoes'] = opts.ajaxData.paramsComuns;
            }

            if (opts.ajaxData.customColumns){
                data['dt_customColumns'] = opts.ajaxData.customColumns;
            }

            $.fn.dataTable.montarData(data, $(`#${idFormParams}`));
        },
        "error":  function (jqXHR, textStatus, errorThrown) {
            function extrairMensagens(xhr, plural, singular) {
                let xmlMesagens = $($.parseXML(xhr)).find(plural);
                let arrMensagens = [];
                xmlMesagens.each(function () {
                    arrMensagens.push($(this).find(singular).attr('descricao').infraReplaceAll('\\n', '<br>'));
                });
                return arrMensagens;
            }

            const arrErros = extrairMensagens(jqXHR.responseText,'erros', 'erro');
            const arrWarnings = extrairMensagens(jqXHR.responseText,'warnings', 'warning');

            $('#' + opts.tableId + '_processing').hide();
            if (arrErros.length > 0) {
                eproc.toast.error({
                    text: arrErros.join('<br>'),
                    data: {delay: 5000}
                });
            }
            if (arrWarnings.length > 0) {
                eproc.toast.warning({
                    text: arrWarnings.join('<br>'),
                    data: {delay: 5000}
                });
            }
        },
    }

    let columns = opts.columns ? opts.columns : [];
    let defaultOrderableColumn = 0;

    if (opts.isSelecionavel) {
        defaultOrderableColumn++;
        columns = configColunaSelecionavel(columns);
    }
    if (opts.isResponsivoComDetalhe) {
        defaultOrderableColumn++;
        columns = configColunaExpandirDetalhes(columns);
    }
    if (opts.colunaAcoes) {
        columns = configColunaAcoes(columns, opts.isResponsivoComDetalhe);
    }
    const dt = tableEl.DataTable(_.merge({
        ajax: ajax,
        columns: columns,
        serverSide: true,
        order: [[defaultOrderableColumn, 'asc']],
        responsive: getResponsive(opts.isResponsivoComDetalhe),
        buttons: {buttons: opts.buttons, dom: $.fn.dataTable.defaults.buttons.dom},
        paging: false,
        drawCallback: drawCallback,
        initComplete: function( settings, json ) {
            $(dt.table().node()).parents('.dataTables_wrapper').find('.container-acoes-bloco').prepend(opts.tableTitle);
        },
    }, dtAttributes));

    function getResponsive(isResponsivoComDetalhe) {
        let responsive = {
            details: {
                display: $.fn.dataTable.Responsive.display.childRowImmediate,
                type: 'none',
                target: ''
            }
        }
        if (isResponsivoComDetalhe) {
            responsive = {
                details: {
                    type: 'column'
                }
            }
        }
        return responsive;
    }

    function configColunaAcoes(columns, isResponsivoComDetalhe = false) {
        let colAcoes = {
            "data": "col_acoes",
            "title": 'Ações',
            "orderable": false,
            "width": "41px",
            "className": 'acoes-table not-export-col',
            "render": function (data, type, row) {
                const acoes = opts.colunaAcoes(data, type, row);
                return renderActions(acoes);
            }
        };
        if (isResponsivoComDetalhe) {
            colAcoes.responsivePriority = 1000;
        }
        columns.push(colAcoes);
        return columns;
    }

    function configColunaSelecionavel(columns) {
        columns.unshift({
            'data': 'col_checkbox',
            'title': '<input type="checkbox" name="chkTodos">',
            'orderable': false,
            'className': 'text-center not-export-col',
            'width': '15px',
            'render': function(data, type, row, meta) {
                return '<input type=checkbox name="chkRow_' + row.DT_RowId + '">';
            }
        });
        return columns;
    }

    function configColunaExpandirDetalhes(columns) {
        columns.unshift({
            'data': 'col_detalhes',
            'title': '',
            'orderable': false,
            'className': 'dtr-control text-center not-export-col',
            'width': '15px',
        });
        return columns;
    }

    function getCheckedData(id) {
        return $('#' + id + ' [name^=chkRow]:checked').map(function () {
            return $('#' + id).dataTable().api().row(this.parentElement.parentElement).data();
        }).toArray();
    }

    $('body').on('click', `#${opts.tableId} input[name="chkTodos"]`, function () {
        const table = $(this).closest('table')
        table.find('input[name^="chkRow"]').prop('checked', this.checked)
        var chkData = getCheckedData(opts.tableId)
        onSelectCallback(chkData);
        toggleAcoes(chkData);
    });


    tableEl.on('change', '[name^=chkRow]', function () {
        var chkData = getCheckedData(opts.tableId);
        onSelectCallback(chkData);
        toggleAcoes(chkData);
    });

    $.fn.dataTable.Api.register('rows().getSelected()', function () {
        var id = this.table().node().id;
        return getCheckedData(id);
    });

    $.fn.dataTable.Api.register('rows().getSelectedData()', function (colOrColsToFilter) {
        var id = this.table().node().id;
        var rows = getCheckedData(id);

        return rows.map(function (row) {
            if (Array.isArray(colOrColsToFilter)) {
                return _.pick(row, colOrColsToFilter);
            } else {
                if (!row.hasOwnProperty(colOrColsToFilter)) {
                    throw "Chave '" + colOrColsToFilter + "' não encontrado na linha selecionada";
                }

                return row[colOrColsToFilter]
            }
        })
    });

    function toggleAcoes(rows) {
        let acoesWrapper = $(`#${opts.tableId}-acoes-wrapper`);
        let botoes = $(`#${opts.tableId}_wrapper .btn-acoes-wrapper`);
        if (rows.length) {
            acoesWrapper.addClass('d-flex');
            botoes.addClass('d-flex');
        } else {
            acoesWrapper.removeClass('d-flex');
            botoes.removeClass('d-flex');
        }
    }
}


export default {
    createAcaoConsultar: createAcaoConsultar,
    createAcaoAlterar: createAcaoAlterar,
    createAcaoDesativar: createAcaoDesativar,
    createAcao: createAcao,
    build: build,
    ACTION_TYPES: ACTION_TYPES,
}